@font-face {
	font-family: suitExBold;
	src: url("./asset/font/SUIT-ExtraBold.ttf") format("truetype"),
		 url("./asset/font/SUIT-ExtraBold.otf") format("opentype");
}
@font-face {
	font-family: suitBold;
	src: url("./asset/font/SUIT-Bold.ttf") format("truetype"),
		 url("./asset/font/SUIT-Bold.otf") format("opentype");
}
@font-face {
	font-family: suitSemiBold;
	src: url("./asset/font/SUIT-SemiBold.ttf") format("truetype"),
		 url("./asset/font/SUIT-SemiBold.otf") format("opentype");
}
@font-face {
	font-family: suitMedium;
	src: url("./asset/font/SUIT-Medium.ttf") format("truetype"),
		 url("./asset/font/SUIT-Medium.otf") format("opentype");
}
@font-face {
	font-family: suitRegular;
	src: url("./asset/font/SUIT-Regular.ttf") format("truetype"),
		 url("./asset/font/SUIT-Regular.otf") format("opentype");
}